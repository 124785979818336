import React from "react";
import { Link } from "react-router-dom";
import SVGIcons from "../../SVGIcons";
import { ROUTES_CONFIG } from "../Sidebar.config";
import TooltipContainer from "../../Tooltip";

function NavigationLinks(props) {
  const handleNavBarStyle = (pathname) => {
    let urlSearchValue = props?.location?.search?.split("?")[1];
    if (
      (pathname == "/downloadpreview" && urlSearchValue == "insight") ||
      pathname == "/viewtabinsights"
    ) {
      return "/insights";
    } else if (
      pathname == "/downloadpreview" &&
      urlSearchValue == "dashboard"
    ) {
      return "/dashboard";
    } else if (pathname == "/viewtabarticles") return "/home";
    else {
      return pathname;
    }
  };
  return (
    <>
      {(props?.CurrentUserProfile?.userProfile == 1
        ? ROUTES_CONFIG["Admin"]
        : ROUTES_CONFIG["Analyst"]
      )
        .filter(
          (item) =>
            item.name != "Download Preview" &&
            item.name != "View Tab Insights" &&
            item.name != "View Tab Articles"
        )
        .map(({ name, route, icon }, index) => (
          <li
            key={index}
            className={
              route === handleNavBarStyle(props.location.pathname)
                ? props.menuActive
                : ""
            }
          >
            <TooltipContainer title={name} placement={"right"}>
              <Link to={route}>
                <span>
                  <SVGIcons type={icon} />
                </span>
              </Link>
            </TooltipContainer>
          </li>
        ))}
    </>
  );
}
export default NavigationLinks;

import { connect } from "react-redux";

import {
  FilterFieldValues,
  setInsightData,
  setSearchData,
  advanceSearchStarted,
  advanceSearchSuccess,
  advanceSearchFailed
} from "../../Redux/Actions";

import AdvanceSearch from "./AdvanceSearch";
import { SEARCH_TYPE } from "constants";
import { DEFAULT_DATE_FILTER_VALUE } from "constants";
import { formatSearchPayload, searchApiHandler } from "../Home/Helper/handlers";
import { RefreshSearch } from "Redux/Reducers/Home/HomeState";


function mapStateToProps(state, props) {
  let isInsights = props.isInsights;
  return {
    FilterFields: state.FilterFields,
    search: isInsights ? state.CreateInsights : state.Search.search,
  };
}

function mapDispatchToProps(dispatch, props) {
  let isInsights = props.isInsights;
  const functionObject = {
    setData: isInsights ? setInsightData : setSearchData,
  };

  return {
    advanceSearch: (params) => {
      dispatch(
        functionObject.setData({
          filter: {},
          dateFilter: DEFAULT_DATE_FILTER_VALUE,
          advanceSearchPayloadData: params.data,
          searchType: SEARCH_TYPE.ADVANCED_SEARCH,
        })
      );
      dispatch(RefreshSearch());
    },
    clearAdvanceSearchPayload: () =>
      dispatch(
        functionObject.setData({
          advanceSearchPayloadData: {},
          searchType: SEARCH_TYPE.NORMAL_SEARCH,
        })
      ),
    loadFilterFieldValues: (payload) => dispatch(FilterFieldValues(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceSearch);

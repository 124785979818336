import React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import TableElement from "../../Components/TableElement";
import { TABLE_COLUMNS } from "./TableColumns";
import { ContentLoader } from "../../Components/ContentLoader";
import Pagination from "../../Components/Pagination";
import ContentBody from "../../Components/ContentBody";

import globel from "../../styles/globel/style.module.scss";
import ErrorState from "../../Components/ErrorState";
import { DEFAULT_PAGE_COUNT } from "../../constants";
import ButtonElement from "../../Components/ButtonElement";
import { EditUserGroupModal } from "./TableActionModal/EditUserGroupModal";
import { DeleteUserGroupModal } from "./TableActionModal/DeleteUserGroupModal";
import style from "./style.module.scss";
import { addUserGroupAction, getUserGroupAction, updateUserGroupAction, deleteUserGroupAction } from "../../Redux/Actions/UserGroups";
const UserGroups = props => {

  const initState = {
    sortedInfo: { columnKey: 'id', field: 'id', order: 'asc' },
    addModalVisible: false,
    updateModalVisible: false,
    deleteModalVisible: false,
    pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_COUNT },
    userGroups: [],
    totalCount: 0,
    group: { name: undefined, note: undefined },
  };

  const [state, setLocalState] = useState(initState);

  const setState = (data) => {
    setLocalState(prevState => {
      return { ...prevState, ...data };
    });
  };

  useEffect(() => {
    props.getUserGroup(getpayload());
  }, []);


  useEffect(() => {
    setState({ userGroups: props.userGroups });
  }, [props.userGroups]);

  useEffect(() => {
    setState({ totalCount: props.totalCount });
  }, [props.totalCount]);

  useEffect(() => {
    props.getUserGroup(getpayload());
  }, [state.pagination, state.sortedInfo]);

  const setAddModalVisibility = (visible) => {
    setState({ addModalVisible: visible });
  };
  const setUpdateModalVisibility = (visible) => {
    setState({ updateModalVisible: visible });
  };
  const setDeleteModalVisibility = (visible) => {
    setState({ deleteModalVisible: visible });
  };

  const onDetailsChange = (field, value) => {
    const json = {};
    json[field] = value;
    setState({ group: { ...state.group, ...json } });
  };

  const handleAdd = () => {
    setState({ group: { name: undefined, note: undefined } });
    setAddModalVisibility(true);
  }

  const cancelAdd = () => {
    setAddModalVisibility(false);
  };

  const onAddSubmit = () => {
    props.addUserGroup(state.group);
    setAddModalVisibility(false);
    props.getUserGroup(getpayload());
  };

  const handleUpdate = (record) => {
    setState({ group: record });
    setUpdateModalVisibility(true);
  };

  const cancelUpdate = (record) => {
    setUpdateModalVisibility(false);
  };

  const onUpdateSubmit = () => {
    props.updateUserGroup(state.group);
    setUpdateModalVisibility(false);
    //todo do we need to want reloaded data to include the newly added group?
    props.getUserGroup(getpayload());
  };

  const handleDelete = (record) => {
    setState({ group: record });
    setDeleteModalVisibility(true);
  };

  const cancelDelete = (record) => {
    setDeleteModalVisibility(false);
  };

  const onConfirmDelete = () => {
    props.deleteUserGroup(state.group);
    setDeleteModalVisibility(false);
    props.getUserGroup(getpayload());
  };

  const handleSortingChange = (sorter) => {
    const sortedInfo = {
      columnKey: sorter.columnKey,
      field: sorter.field,
      order: sorter.order == 'descend' ? 'desc' : 'asc',
    };
    setState({ sortedInfo: sortedInfo });
  };

  const getpayload = () => {
    const { pagination, sortedInfo } = state;
    const data = {
      pageNumber: pagination.pageNumber,
      pageSize: pagination.pageSize,
      sortField: sortedInfo.field,
      sortOrder: sortedInfo.order,
    };
    return data;
  };

  const handlePagination = (pageNumber, pageSize) => {
    setState({ pagination: { pageNumber: pageNumber, pageSize: pageSize } });
  };

  const GroupPagination = () => {
    return (
      <Pagination
        pageSize={state.pagination?.pageSize}
        current={state.pagination?.pageNumber}
        total={state.totalCount}
        onChange={handlePagination}
        showSizeChanger={true}
        pageSizeOptions={["10", "20", "50", "100"]}
      />
    );
  };

  const showContentLoader = () => {
    return <ContentLoader height={2} width={100} number={10} hasWrapper={true} />;
  };

  return (
    <>
      <ContentBody>
        {props.userListloading ? (
          showContentLoader()
        ) : (
          <>
            {props.error ? (
              <ErrorState
                customHeight={"600"}
                content="ERROR"
                type={"errorImage"}
                errorMessage={props.errorMessage}
              />
            ) : (
              <>
                {state.userGroups.length > 0 && (
                  <>
                    <div className={globel.paginationWrapper}>
                      <div className={style.addButtonWrapper}>
                        <ButtonElement
                          size={"small"}
                          onClick={handleAdd}
                          children={"Add Group"}
                        />
                      </div>
                      <EditUserGroupModal
                        title={"Add User Group"}
                        buttonTitle={"Add"}
                        onCancel={cancelAdd}
                        visible={state.addModalVisible}
                        onChange={onDetailsChange}
                        onSubmit={onAddSubmit}
                        loader={props.addUserLoading}
                        group={state.group}
                      />
                    </div>
                    <div className={globel.paginationWrapper}>
                      <div className={globel.searchresultCount}>
                        <span>
                          {state.userGroups.length > 0
                            ? "Showing " +
                            state.userGroups.length +
                            " out of " +
                            state.totalCount +
                            " Results"
                            : ""}
                        </span>
                      </div>
                      <div className={globel.searchresultPage}>
                        {state.totalCount > DEFAULT_PAGE_COUNT ? GroupPagination() : ""}
                      </div>
                    </div>
                    <TableElement
                      columns={TABLE_COLUMNS.COLUMNS({
                        sortedInfo: state.sortedInfo,
                        handleUpdate: handleUpdate,
                        handleDelete: handleDelete,
                      })}
                      data={props.userGroups.map((item, key) => ({
                        ...item,
                        key,
                      }))}
                      onChange={handleSortingChange}
                      scrollY={"calc(100vh - 270px)"}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </ContentBody>
      <EditUserGroupModal
        title={"Update Group"}
        buttonTitle={"Update"}
        onCancel={cancelUpdate}
        visible={state.updateModalVisible}
        onChange={onDetailsChange}
        onSubmit={onUpdateSubmit}
        group={state.group}
        loader={props.updateUserLoading}
      />
      <DeleteUserGroupModal
        visible={state.deleteModalVisible}
        onCancel={cancelDelete}
        confirmDelete={onConfirmDelete}
        loader={props.deleteUserLoading}
        group={state.group}
      />
    </>
  );
};

const mapStateToProps = state => {
  const fromState = {
    ...state.UserGroups,

  };
  return fromState;
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUserGroup: (payload) => {
      dispatch(addUserGroupAction(payload));
    },
    getUserGroup: (payload) => {
      dispatch(getUserGroupAction(payload));
    },
    updateUserGroup: (payload) => {
      dispatch(updateUserGroupAction(payload));
    },
    deleteUserGroup: (payload) => {
      dispatch(deleteUserGroupAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserGroups);

import React from "react";

import { Dropdown } from "../../../Dropdown";
import ModalWindow from "../../../ModalWindow";
import SavedSearches from "../../../SavedSearches";

export const MyLoadDropdown =(props)=> {

    const propsForSavedSearches = {
        search: props.search, 
        // for ShowModalWindow: 
        state: {
            showSavedSearchDeleteModal: props.showSavedSearchDeleteModal,
            savedSearchDeleteLoading: props.savedSearchDeleteLoading, 
            exportLoader: props.exportLoader, 

            pagination: {
                pageNumber: props.pagination.pageNumber,
                pageSize: props.pagination.pageSize
            }
        },
        deleteModalCloseHandler: props.deleteModalCloseHandler,
        deleteModalHandlerYes: props.deleteModalHandlerYes, 
        // end for ShowModalWindow

        search: props.search,
        loading: props.loading, 
        
        handleDelete: props.handleDelete,
        handleSetSaved: props.handleSetSaved,
        exportHandler: props.exportHandler,

        handleSavedSearchPagination: props.handleSavedSearchPagination

        


    };

    return (
        <Dropdown addOnClass={props.showLoadMenu} customButton={<></>}>
        {props.showLoadMenu ? (
            <ModalWindow
            open={props.showLoadMenu}
            // title={'Saved Searches'}
            width={900}
            okText="Close"
            onOk={(e) => props.handleLoad(false)}
            isNonMaskable={true}
            isNotClosable={true}
            isHideCancelButton={true}
            >
            <SavedSearches {...propsForSavedSearches} />
            </ModalWindow>
        ) : (
            <></>
        )}
        </Dropdown>
    );
}

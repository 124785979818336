/* eslint-disable */
import {
  SEARCH_FAILED,
  SEARCH_STARTED,
  SEARCH_SUCCESS,
  SEARCH_SAVED,
  SEARCH_DELETE_SAVED,
  SEARCH_SAVED_SUCCESS,
  ADVANCE_SEARCH_STARTED,
  ADVANCE_SEARCH_FAILED,
  ADVANCE_SEARCH_SUCCESS,
  ADD_TAGS_START,
  ADD_TAGS_SUCCESS,
  ADD_TAGS_FAILED,
  SET_SEARCH_DATA,
  ADD_TAGS_MODAL_TOGGLE,
} from "../../Redux.constants";
import {
  deleteSaved,
  searchFailed,
  searchSaved,
  searchSavedSuccess,
  searchStarted,
  searchSuccess,
  setSearchDataState,
} from "./Helper";
import {
  advanceSearchFailed,
  advanceSearchStarted,
  advanceSearchSuccess,
} from "./Helper/advanceSearchHelper";
import { addTagFailed, addTagStart, addTagSuccess, addTagModalToggle } from "./Helper/tagsHelper";
import { INITIAL_STATE } from "./initialState";

export default function Search(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SEARCH_STARTED:
      return searchStarted(state, action.payload);
    case SEARCH_FAILED:
      return searchFailed(state, action.payload);
    case SEARCH_SUCCESS:
      return searchSuccess(state, action.payload);
    case SEARCH_SAVED:
      return searchSaved(state, action.payload);
    case SEARCH_DELETE_SAVED:
      return deleteSaved(state, action.payload);
    case SEARCH_SAVED_SUCCESS:
      return searchSavedSuccess(state, action.payload);
    case ADVANCE_SEARCH_STARTED:
      return advanceSearchStarted(state, action.payload);
    case ADVANCE_SEARCH_FAILED:
      return advanceSearchFailed(state, action.payload);
    case ADVANCE_SEARCH_SUCCESS:
      return advanceSearchSuccess(state, action.payload);
    case ADD_TAGS_START:
      return addTagStart(state, action.payload);
    case ADD_TAGS_SUCCESS:
      return addTagSuccess(state, action.payload);
    case ADD_TAGS_FAILED:
      return addTagFailed(state, action.payload);
    case ADD_TAGS_MODAL_TOGGLE:
      return addTagModalToggle(state, action.payload);
    case SET_SEARCH_DATA:
      return setSearchDataState(state, action.payload);
    default:
      return { ...state };
  }
}

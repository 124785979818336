/*eslint-disable*/
import React, { Fragment, useState } from 'react';

import ToggleSwitch from '../../../Components/ToggleSwitch';
import { formatSelectValue, formatSelectValueList } from '../helper';
import SelectFilter from './SelectFilterFacet';
import SelectValue from './SelectValueFacet';
import SelectOptions from './SelectOptions';
import { checkIsTexOrDatetField } from '../controller';
import DateRangeBlock from './DateRangeBlock';
import {
  capitalizeFirstLetter,
  formatFilterLabel,
} from '../../../utils/formatFilterLabel';
import { convertStringToArray } from '../helperFunctions';

import {
  SELECT_DATE_PUBLISHED_TYPE,
  SELECT_OPTION_LIST,
} from '../../../Constants/AdvanceSearch/AdvanceSearch.constants';

import style from './style.module.scss';

function Facet(props) {
  const [sliceCount, setSliceCount] = useState(100);
  const [searchValue, setSearchValue] = useState({});
  const handleSelectFilter = (filterField) => {
    const filtersList = props.FilterFields.advanceSearchFilter;
    if (
      filtersList[filterField]?.loading !== true &&
      filtersList[filterField]?.value?.length === 0
    ) {
      props.loadFilterFieldValues({ fieldName: filterField, searchType: 3 });
    }
    props.handleSelectFilter({
      value: filterField,
      facet: 'facet',
      group: props.value,
      filter: props.filter,
    });
  };
  const handleSelectClick = (filterField) => {
    const listData = props.FilterFields.advanceSearchFilter;
    const key = props.group[props.value][filterField].key;
    if (!listData[key]?.value.length) {
      if (
        listData[key]?.loading !== true &&
        listData[key]?.value?.length === 0
      ) {
        props.loadFilterFieldValues({ fieldName: key, searchType: 3 });
      }
    }
  };
  const onPopupScroll = (event) => {
    var target = event.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setSliceCount((prevData) => prevData + 100);
    }
  };
  const onSearchHandler = (value, field) => {
    setSearchValue((prev) => ({ ...prev, [field]: value }));
  };

  const handleSelectAll = (searchedValue)=>{
   
    const group = props.group[props.value][props.filter].key;

    let items = props.FilterFields.advanceSearchFilter[group].data;
    if (searchedValue){
      items = items.filter((item) => item.value.toLowerCase().includes(searchedValue.toLowerCase()));
    }
   
    const value = 'value1'; // seems always to be 'value1' ? 
    props.handleSelectValue({
      result: items.map(x=>x.value),
      filter: props.filter,
      group: props.value,
      facet: "facet",
      value,
      option: true,
      select: true,
    });
  };

  const handleClearAll = ()=>{
    const value = 'value1';
    props.handleSelectValue({
      result: [],
      filter: props.filter,
      group: props.value,
      facet: "facet",
      value,
      option: true,
      select: true,
    });
  };


  const xxx = Object.keys(props.group[props.value][props.filter]['facet']);
  return (
    <ul className={style.facetBlock}>
      {xxx.map(
        (value, key) => {
          return (
            <Fragment key={value + key}>
              <li>
                <div className={style.facetElement}>
                  <div className={style.facetElement__item}>
                    <div
                      className={style.facetitemPane}
                      hidden={key == 0 ? false : true}
                    >
                      <SelectFilter
                        onChange={handleSelectFilter}
                        value={
                          formatFilterLabel(
                            props.group[props.value][props.filter]?.key
                          ) || null
                        }
                        optionList={formatSelectValue(props)}
                        style={{ caretColor: 'transparent' }}
                      />
                    </div>
                    <div className={style.facetitemPane}>
                      <div
                        className={style.facetswitch}
                        hidden={key == 0 ? true : false}
                      >
                        <span>And</span>
                        <span>
                          <ToggleSwitch
                            size={'small'}
                            handleChange={(e) =>
                              props.handleFacetToggle({
                                value,
                                status: e,
                                facet: 'facet',
                                group: props.value,
                                filter: props.filter,
                              })
                            }
                            value={
                              props.group[props.value][props.filter]['facet'][
                                value
                              ]?.isOR
                            }
                          />
                        </span>
                        <span>OR</span>
                      </div>
                      {checkIsTexOrDatetField(
                        props.group[props.value][props.filter].key
                      ) == 'text' ? (
                        <SelectValue
                          onChange={(e) =>
                            props.handleSelectValue({
                              result: e.value == '' ? '' : e.target.value,
                              filter: props.filter,
                              group: props.value,
                              facet: 'facet',
                              value,
                              option: true,
                            })
                          }
                          title={`Enter ${capitalizeFirstLetter(
                            props.group[props.value][props.filter].key
                          )}`}
                          placeholder={`Enter ${
                            props.group[props.value][props.filter]?.key
                          }..`}
                          value={
                            props.group[props.value][props.filter]['facet'][
                              value
                            ]?.data
                          }
                        />
                      ) : checkIsTexOrDatetField(
                          props.group[props.value][props.filter].key
                        ) == 'date' ? (
                        <SelectValue
                          onChange={(e) =>
                            props.handleSelectValue({
                              result: e,
                              filter: props.filter,
                              group: props.value,
                              facet: 'facet',
                              value,
                            })
                          }
                          value={
                            props.group[props.value][props.filter]['facet'][
                              value
                            ]?.data
                          }
                          optionList={SELECT_DATE_PUBLISHED_TYPE}
                          inputType={'selectElement'}
                          placeholder={'Select type'}
                          title={'Select Date Filter Type'}
                          disabled={
                            !props.group[props.value][props.filter].key
                              ? true
                              : false
                          }
                          style={{ caretColor: 'transparent' }}
                        />
                      ) : props.group[props.value][props.filter]?.key ==
                        undefined ? (
                        <SelectValue
                          onChange={(e) =>
                            props.handleSelectValue({
                              result: e.value == '' ? '' : e.target.value,
                              filter: props.filter,
                              group: props.value,
                              facet: 'facet',
                              value,
                              option: true,
                            })
                          }
                          title={`enter value`}
                          placeholder={`Enter the value..`}
                          value={
                            props.group[props.value][props.filter]['facet'][value]?.data
                          }
                        />
                      ) : (
                        <SelectValue
                          handleSelectAll={handleSelectAll}
                          handleClearAll={handleClearAll}
                          onChange={(e) => {
                            props.handleSelectValue({
                              result: e,
                              filter: props.filter,
                              group: props.value,
                              facet: 'facet',
                              value,
                              option: true,
                              select: true,
                            });
                          }}
                          value={
                            props.group[props.value][props.filter]['facet'][
                              value
                            ]?.data
                              ? convertStringToArray(
                                  props.group[props.value][props.filter][
                                    'facet'
                                  ][value]?.data
                                )
                              : []
                          }
                          optionList={
                            formatSelectValueList({
                              props,
                              sliceCount,
                              searchValue:
                                searchValue[
                                  props.group[props.value][props.filter].key
                                ] ?? '',
                            }) || []
                          }
                          onBlur={() => {
                            onSearchHandler(
                              '',
                              props.group[props.value][props.filter].key
                            );
                          }}
                          inputType={'selectElement'}
                          placeholder={'Select value'}
                          disabled={
                            !props.group[props.value][props.filter].key ||
                            props.FilterFields.advanceSearchFilter[
                              props.group[props.value][props.filter].key
                            ]?.loading
                          }
                          loading={
                            props.FilterFields.advanceSearchFilter[
                              props.group[props.value][props.filter].key
                            ]?.loading
                          }
                          onSearch={(value) => {
                            onSearchHandler(
                              value,
                              props.group[props.value][props.filter].key
                            );
                          }}
                          style={{ caretColor: 'transparent' }}
                          mode='multiple'
                          maxTagCount={5}
                          className={'disableTagsStyle'}
                          onPopupScroll={onPopupScroll}
                          onClick={() => handleSelectClick(props.filter)}
                        />
                      )}
                    </div>
                    {checkIsTexOrDatetField(
                      props.group[props.value][props.filter].key
                    ) !== 'date' ? (
                      <div className={style.facetitemPane}>
                        <div className={style.facetSelect}>
                          <SelectOptions
                            value={
                              props.group[props.value][props.filter]['facet'][
                                value
                              ]?.option || 'All'
                            }
                            onChange={(e) =>
                              props.handleSelectOption({
                                result: e,
                                filter: props.filter,
                                group: props.value,
                                facet: 'facet',
                                value,
                              })
                            }
                            optionList={SELECT_OPTION_LIST}
                          />
                        </div>
                      </div>
                    ) : checkIsTexOrDatetField(
                        props.group[props.value][props.filter].key
                      ) == 'date' &&
                      props.group[props.value][props.filter]['facet'][value]
                        ?.data ? (
                      <div className={style.facetitemPane}>
                        <div className={style.facetSelect}>
                          <DateRangeBlock
                            checkValue={
                              props.group[props.value][props.filter]['facet'][
                                value
                              ]
                            }
                            valueName={value}
                            handleSelectValue={props.handleSelectOption}
                            {...props}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </li>
            </Fragment>
          );
        }
      )}
    </ul>
  );
}

export default Facet;

import React from 'react';
import { Table } from 'antd';
import './style.scss';
const TableElement = (props) => {
  return (
    <Table
      columns={props.columns}
      dataSource={props.data}
      pagination={false}
      sorter={true}
      onChange={props.onChange}
      scroll={{ x: props.scrollX, y: props.scrollY }}
      loading={props.loading}
    />
  );
};
export default TableElement;

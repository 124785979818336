import {
  GET_FILTER_FIELDS_SUCCESS,
  GET_FILTER_FIELDS_FAILED,
  GET_FILTER_FIELDS_STARTED,
  GET_FILTER_FIELD_VALUES_STARTED,
  GET_FILTER_FIELD_VALUES_SUCCESS,
  GET_FILTER_FIELD_VALUES_FAILED,
  CLEAR_FILTER_FIELD_DATA,
} from "../../Redux.constants";
import { getAllFilterFieldsList } from "./helper";

import { filteFieldsByCount } from "../../../api/search";
import { KEYS } from "../../../dataKeys";
import { handleApiError } from "../../../utils/commonUtils";

export function FilterFields() {
  return async (dispatch) => {
    dispatch({
      type: GET_FILTER_FIELDS_STARTED,
      payload: {},
    });
    try {
      const {
        data,
        allFields,
        allFieldsWithMetadata,
      } = await getAllFilterFieldsList();
      dispatch({
        type: GET_FILTER_FIELDS_SUCCESS,
        payload: {
          newSettings: data,
          allFields,
          advanceSearchFilter: data,
          allFieldsWithMetadata,
        },
      });
    } catch (error) {
      handleApiError(error, () =>
        dispatch({
          type: GET_FILTER_FIELDS_FAILED,
          payload: error,
        })
      );
    }
  };
}

/**
 *
 * @param {{fieldName: string}} payload
 * @returns
 */
export function FilterFieldValues(payload) {
  return async (dispatch) => {
    dispatch({
      type: GET_FILTER_FIELD_VALUES_STARTED,
      payload: { fieldName: payload.fieldName },
    });
    try {
      let email = localStorage.getItem(KEYS.ACCESS_EMAIL);
      const { data } = await filteFieldsByCount({
        fieldName: payload.fieldName,
        emailId: email,
        searchType: payload.searchType ? payload.searchType : 0,
        payload: payload.searchPayload,
      });
      const fieldValues =
        data.result?.sort((a, b) => {
          a["value"].toLowerCase() < b["value"].toLowerCase()
            ? -1
            : a["value"].toLowerCase() > b["value"].toLowerCase()
            ? 1
            : 0;
        }) || [];
      dispatch({
        type: GET_FILTER_FIELD_VALUES_SUCCESS,
        payload: { fieldName: payload.fieldName, fieldValues },
      });
    } catch (error) {
      handleApiError(error, () =>
        dispatch({
          type: GET_FILTER_FIELD_VALUES_FAILED,
          payload: { fieldName: payload.fieldName, error },
        })
      );
    }
  };
}

export function ClearFilterFieldData() {
  return (dispatch) => {
    dispatch({ type: CLEAR_FILTER_FIELD_DATA });
  };
}

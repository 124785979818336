import {
  ADD_USER_GROUP_START,
  ADD_USER_GROUP_SUCCESS,
  ADD_USER_GROUP_FAILED,
  GET_USER_GROUP_START,
  GET_USER_GROUP_SUCCESS,
  GET_USER_GROUP_FAILED,
  UPDATE_USER_GROUP_START,
  UPDATE_USER_GROUP_SUCCESS,
  UPDATE_USER_GROUP_FAILED,
  DELETE_USER_GROUP_START,
  DELETE_USER_GROUP_SUCCESS,
  DELETE_USER_GROUP_FAILED,
} from '../../Redux.constants';
import {
  addUserGroupStarted,
  addUserGroupSuccess,
  addUserGroupFailed,
  getUserGroupStarted,
  getUserGroupSuccess,
  getUserGroupFailed,
  updateUserGroupStarted,
  updateUserGroupSuccess,
  updateUserGroupFailed,
  deleteUserGroupStarted,
  deleteUserGroupSuccess,
  deleteUserGroupFailed,
} from './Helper';

import {INITIAL_STATE} from './initialState';

export default function UserGroups(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_USER_GROUP_START:
      return addUserGroupStarted(state, action.payload);
    case ADD_USER_GROUP_SUCCESS:
      return addUserGroupSuccess(state, action.payload);
    case ADD_USER_GROUP_FAILED:
      return addUserGroupFailed(state, action.payload);
    case GET_USER_GROUP_START:
      return getUserGroupStarted(state, action.payload);
    case GET_USER_GROUP_SUCCESS:
      return getUserGroupSuccess(state, action.payload);
    case GET_USER_GROUP_FAILED:
      return getUserGroupFailed(state, action.payload);  
    case UPDATE_USER_GROUP_START:
      return updateUserGroupStarted(state, action.payload);
    case UPDATE_USER_GROUP_SUCCESS:
      return updateUserGroupSuccess(state, action.payload);
    case UPDATE_USER_GROUP_FAILED:
      return updateUserGroupFailed(state, action.payload);  
    case DELETE_USER_GROUP_START:
      return deleteUserGroupStarted(state, action.payload);
    case DELETE_USER_GROUP_SUCCESS:
      return deleteUserGroupSuccess(state, action.payload);
    case DELETE_USER_GROUP_FAILED:
      return deleteUserGroupFailed(state, action.payload);  
    default:
      return {...state};
  }
}

import { deleteSaved, getSavedSearchList } from "../../../../api/search";
import { handleApiError } from "../../../../utils/commonUtils";
import {
  SEARCH_DELETE_SAVED,
  SEARCH_FAILED,
  SEARCH_SAVED,
  SEARCH_SAVED_SUCCESS,
  SEARCH_STARTED,
} from "../../../Redux.constants";
import { saveRecentApi } from "../Helper/helper";

export async function saveResultHandler({ dispatch, payload, callBack }) {
  try {
    let data = await saveRecentApi(payload, true);
    dispatch({
      type: SEARCH_SAVED_SUCCESS,
      payload: data.data.result,
    });
    callBack();
  } catch (error) {
    handleApiError(error, () =>
      callBack({ error: true, message: error.response.data.result })
    );
  }
}

export async function deltedSavedHandler({ dispatch, payload, callBack }) {
  try {
    await deleteSaved({ Emailid: payload.emailid, id: payload.id });
    dispatch({
      type: SEARCH_DELETE_SAVED,
      payload: payload.id,
    });
    callBack();
  } catch (error) {
    handleApiError(error, () =>
      dispatch({ type: SEARCH_FAILED, payload: error })
    );
  }
}

export async function savedSearchPaginateDispatchHelper({ dispatch, payload }) {
  dispatch({
    type: SEARCH_STARTED,
    payload: { saved: true },
  });
  try {
    const result = await getSavedSearchList(payload);
    dispatch({
      type: SEARCH_SAVED,
      payload: result.data.result,
    });
  } catch (error) {
    handleApiError(error, () =>
      dispatch({ type: SEARCH_FAILED, payload: error })
    );
  }
}

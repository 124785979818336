import {errorMEssageHandler} from '../../../../utils/ErrorHandler';

export function addUserGroupStarted(state, payload) {
  return {
    ...state,
    loading: true,
    error: false,
    
  };
}

export function addUserGroupSuccess(state, payload) {
  return {
    ...state,
    loading: false,
    error: false,
    userGroupStore: payload.data, 
    totalCount: payload.data.length,
  };
}

export function addUserGroupFailed(state, payload) {
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage: errorMEssageHandler(payload?.response?.status),
  };
}

export function getUserGroupStarted(state, payload) {
  return {
    ...state,
    loading: true,
    error: false,
    
  };
}

export function getUserGroupSuccess(state, payload) {
  return {
    ...state,
    loading: false,
    error: false,
    userGroups: payload.data, 
    totalCount: payload.totalCount,
  };
}

export function getUserGroupFailed(state, payload) {
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage: errorMEssageHandler(payload?.response?.status),
  };
}

export function updateUserGroupStarted(state, payload) {
  return {
    ...state,
    loading: true,
    error: false,
    
  };
}

export function updateUserGroupSuccess(state, payload) {
  return {
    ...state,
    loading: false,
    error: false,
    userGroupStore: state.userGroupStore.map(g => g.id === payload.id ? payload : g), 
    userGroups: state.userGroups.map(g => g.id === payload.id ? payload : g), 
  };
}

export function updateUserGroupFailed(state, payload) {
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage: errorMEssageHandler(payload?.response?.status),
  };
}

export function deleteUserGroupStarted(state, payload) {
  return {
    ...state,
    loading: true,
    error: false,
    
  };
}

export function deleteUserGroupSuccess(state, payload) {
  const idxStore = state.userGroupStore.findIndex(g=>g.id===payload.id);
  const idx = state.userGroups.findIndex(g=>g.id===payload.id);
  state.userGroupStore.splice(idxStore, 1);
  state.userGroups.splice(idx, 1);
  return {
    ...state,
    loading: false,
    error: false,
  };
}

export function deleteUserGroupFailed(state, payload) {
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage: errorMEssageHandler(payload?.response?.status),
  };
}
export const INITIAL_STATE = {
  loading: false,
  error: false,
  userGroups: [], //the current user groups in the UI eg as seen in the User Group 
  userGroupStore: [ //api mock, to be removed once API support is done but this key will remain to cache all in the system for selection in user modal
    { id: 1, name: "IT", note: "This is the IT group" }, 
    { id: 2, name: "Office", note: "Office group" }, 
    { id: 3, name: "Cargo", note: "Cargo user group" },
    { id: 4, name: "Retail", note: "Retail user group" },
    { id: 5, name: "Visitor",  note: "visitor user group" },
    { id: 6, name: "Guest", note: "Guest user group" },
    { id: 7, name: "Contractor", note: "Contractor" }
  ],
  totalCount: 7,
};

import React from "react";
import moment from "moment";

import SVGIcons from "../SVGIcons";
import { publishDateFormatter } from "../SearchCard/Helper";
import TooltipContainer from "../Tooltip";

import globel from "../../styles/globel/style.module.scss";

export const SAVED_SEARCHES_CONFIG = {
  COLUMNS: ({ deleteHandler, loadHandler, exportHandler }) => [
    {
      title: "Query Name",
      dataIndex: "searchKeyword",
      key: "searchKeyword",
      sorter: (a, b) => a.searchKeyword.localeCompare(b.searchKeyword),
    },
    {
      title: "Created Date",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (date) => `${publishDateFormatter(date)}`,
      sorter: (a, b) =>
        moment(publishDateFormatter(a.timestamp)).unix() -
        moment(publishDateFormatter(b.timestamp)).unix(),
      defaultSortOrder: "descend",
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (text, record) => (
        <ul className={globel.tableActions}>
          <li>
            <TooltipContainer
              mouseLeaveDelay={0}
              placement={"bottom"}
              title={"Load Result"}
            >
              <span onClick={(e) => loadHandler(record)}>
                <SVGIcons type={"SVG-import"} />
              </span>
            </TooltipContainer>
          </li>
          <li>
            <TooltipContainer
              mouseLeaveDelay={0}
              placement={"bottom"}
              title={"Export Query"}
            >
              <span onClick={(e) => exportHandler(record)}>
                <SVGIcons type={"SVG-export"} />
              </span>
            </TooltipContainer>
          </li>
          <li>
            <TooltipContainer
              mouseLeaveDelay={0}
              placement={"bottom"}
              title={"Delete Search"}
            >
              <span onClick={(e) => deleteHandler(record)}>
                <SVGIcons type={"SVG-delete"} />
              </span>
            </TooltipContainer>
          </li>
        </ul>
      ),
    },
  ],
};

import { ROUTES } from "Constants/Routes/Routes.constants";
import { MyHomeHeader } from "./HomeHeader/MyHomeHeader";
import { MyInsightHeader } from "./InsightHeader/MyInsightHeader";

export const MyHeaderSection = props=>{
    
    const pathName = props.location.pathname;
    const queryParams = new URLSearchParams(window.location.search);
    const queryterm = queryParams.get("page");
    const fromTerm = queryParams.get("from");

    // debugger;

    switch (pathName) {
        case ROUTES.HOME:
            return <MyHomeHeader 
                search = {props.search}
                handleLoad={props.handleLoad}
                handleSave = {props.handleSave}
                handleExport = {props.handleExport}
                exportLoading = {props.exportLoading}
                handleViewNewTab = {props.handleViewNewTab}
                searchData = {props.searchData}
                viewTabButtonVisible={queryterm == "homePage" || fromTerm == "newtab" ? false : true}
                filterVisible = {props.filterVisible}
                handleFilterVisible={props.handleFilterVisible}
                FilterFields={props.FilterFields}
                mainFrameState={props.mainFrameState}
                showSaveModal={props.showSaveModal}
                showLoadMenu={props.showLoadMenu}
                handleSavedResults={props.handleSavedResults}
                handleModal={props.handleModal}
                handleSavedSearchPaginate={props.handleSavedSearchPaginate} // from myhome dispatch
                handleDeleteSaved={props.handleDeleteSaved} // from myhome dispatch
                setSearchData={props.setSearchData} // from dispatch
            />;
        case ROUTES.VIEW_TAB_ARTICLES:
            return <MyHomeHeader {...props} viewTabButtonVisible={false} />;
        case ROUTES.INSIGHTS:
            return (<MyInsightHeader
                loading={props.loading}
                FilterFields={props.FilterFields}
                loadFilterFields={props.loadFilterFields}
                maiframeState={props.maiframeState}
                setMainframeState={props.setMainframeState}
                filterDisable={props.filterDisable}
                filterVisible={props.filterVisible}
                handleFilterVisible={props.handleFilterVisible}
                createInsights={props.createInsights}
                handleSave={props.handleSave}
                loadingOrNoData={props.loadingOrNoData}
                handleSavedInsightModal={props.handleSavedInsightModal}
                viewTabButtonVisible={
                    queryterm == "homePage" || fromTerm == "newtab" ? false : true
                }
            />);
        case ROUTES.DASHBOARD:
        //   return <DashboardHeader {...props} />;
        default:
            return <></>;
    }

}
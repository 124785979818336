export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_STARTED = "LOGIN_STARTED";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const GET_FILTER_FIELDS_STARTED = "GET_FILTER_FIELDS_STARTED";
export const GET_FILTER_FIELDS_SUCCESS = "GET_FILTER_FIELDS_SUCCESS";
export const GET_FILTER_FIELDS_FAILED = "GET_FILTER_FIELDS_FAILED";
export const GET_FILTER_FIELD_VALUES_STARTED =
  "GET_FILTER_FIELD_VALUES_STARTED";
export const GET_FILTER_FIELD_VALUES_SUCCESS =
  "GET_FILTER_FIELD_VALUES_SUCCESS";
export const GET_FILTER_FIELD_VALUES_FAILED = "GET_FILTER_FIELD_VALUES_FAILED";
export const CLEAR_FILTER_FIELD_DATA = "CLEAR_FILTER_FIELD_DATA";

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_STARTED = "RESET_PASSWORD_STARTED";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_STARTED = "SEND_EMAIL_STARTED";
export const SEND_EMAIL_FAILED = "SEND_EMAIL_FAILED";

export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_STARTED = "SEARCH_STARTED";
export const SEARCH_FAILED = "SEARCH_FAILED";
export const SEARCH_SAVED = "SEARCH_SAVED";
export const SEARCH_DELETE_SAVED = "SEARCH_DELETE_SAVED";
export const SEARCH_SAVED_SUCCESS = "SEARCH_SAVED_SUCCESS";

export const ADD_TAGS_SUCCESS = "ADD_TAGS_SUCCESS";
export const ADD_TAGS_START = "ADD_TAGS_START";
export const ADD_TAGS_FAILED = "ADD_TAGS_FAILED";
export const ADD_TAGS_MODAL_TOGGLE = "ADD_TAGS_MODAL_TOGGLE";

export const CLEAR_INSIGHTS_DATA = "CLEAR_INSIGHTS_DATA";
export const INSIGHTS_GET_MAPPING_PAYLOAD_FAILED =
  "INSIGHTS_GET_MAPPING_PAYLOAD_FAILED";
export const INSIGHTS_GET_MAPPING_PAYLOAD_STARTED =
  "INSIGHTS_GET_MAPPING_PAYLOAD_STARTED";
export const INSIGHTS_GET_MAPPING_PAYLOAD_SUCCESS =
  "INSIGHTS_GET_MAPPING_PAYLOAD_SUCCESS";

export const SAVE_INSIGHT_STARTED = "SAVE_INSIGHTS_STARTED";
export const SAVE_INSIGHT_SUCCESS = "SAVE_INSIGHTS_SUCCESS";
export const SAVE_INSIGHT_FAILED = "SAVE_INSIGHTS_FAILED";

export const FETCH_PROJECT_LIST_FAILED = "FETCH_PROJECT_LIST_FAILED";
export const FETCH_PROJECT_LIST_SUCCESS = "FETCH_PROJECT_LIST_SUCCESS";
export const FETCH_PROJECT_LIST_STARTED = "FETCH_PROJECT_LIST_STARTED";

export const GET_INSIGHTS_START = "GET_INSIGHTS_START";
export const GET_INSIGHTS_ERROR = "GET_INSIGHTS_ERROR";
export const GET_INSIGHTS_SUCCESS = "GET_INSIGHTS_SUCCESS";

export const DELETE_INSIGHTS = "DELETE_INSIGHTS";

export const UPDATE_SAVE_INSIGHT_STARTED = "UPDATE_SAVE_INSIGHTS_STARTED";
export const UPDATE_SAVE_INSIGHT_SUCCESS = "UPDATE_SAVE_INSIGHTS_SUCCESS";
export const UPDATE_SAVE_INSIGHT_FAILED = "UPDATE_SAVE_INSIGHTS_FAILED";

export const GET_INSIGHT_DATASET_STARTED = "GET_INSIGHT_DATASET_STARTED";
export const GET_INSIGHT_DATASET_SUCCESS = "GET_INSIGHT_DATASET_SUCCESS";
export const GET_INSIGHT_DATASET_FAILED = "GET_INSIGHT_DATASET_FAILED";

export const GET_USER_PROFILES_STARTED = "GET_USER_PROFILES_STARTED";
export const GET_USER_PROFILES_FAILED = "GET_USER_PROFILES_FAILED";
export const GET_USER_PROFILES_SUCCESS = "GET_USER_PROFILES_SUCCESS";

export const UPDATE_USER_PROFILES_STARTED = "UPDATE_USER_PROFILES_STARTED";
export const UPDATE_USER_PROFILES_SUCCESS = "UPDATE_USER_PROFILES_SUCCESS";
export const UPDATE_USER_PROFILES_FAILED = "UPDATE_USER_PROFILES_FAILED";

export const ADVANCE_SEARCH_FAILED = "ADVANCE_SEARCH_FAILED";
export const ADVANCE_SEARCH_STARTED = "ADVANCE_SEARCH_STARTED";
export const ADVANCE_SEARCH_SUCCESS = "ADVANCE_SEARCH_SUCCESS";

export const GET_ALL_USER_SETTINGS_STARTED = "GET_ALL_USER_SETTINGS_STARTED";
export const GET_ALL_USER_SETTINGS_FAILED = "GET_ALL_USER_SETTINGS_FAILED";
export const GET_ALL_USER_SETTINGS_SUCCESS = "GET_ALL_USER_SETTINGS_SUCCESS";

export const GET_ALL_INSIGHTS_STARTED = "GET_ALL_INSIGHTS_STARTED";
export const GET_ALL_INSIGHTS_FAILED = "GET_ALL_INSIGHTS_FAILED";
export const GET_ALL_INSIGHTS_SUCCESS = "GET_ALL_INSIGHTS_SUCCESS";

export const IMPORT_INSIGHTS_STARTED = "IMPORT_INSIGHTS_STARTED";
export const IMPORT_INSIGHTS_FAILED = "IMPORT_INSIGHTS_FAILED";
export const IMPORT_INSIGHTS_SUCCESS = "IMPORT_INSIGHTS_SUCCESS";

export const GET_DASHBOARD_START = "GET_DASHBOARD_START";
export const GET_DASHBOARD_ERROR = "GET_DASHBOARD_ERROR";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";

export const DELETE_DASHBOARD = "DELETE_DASHBOARD";

export const SAVE_DASHBOARD_START = "SAVE_DASHBOARD_START";
export const SAVE_DASHBOARD_SUCCESS = "SAVE_DASHBOARD_SUCCESS";
export const SAVE_DASHBOARD_ERROR = "SAVE_DASHBOARD_ERROR";

export const EDIT_DASHBOARD_START = "EDIT_DASHBOARD_START";
export const EDIT_DASHBOARD_SUCCESS = "EDIT_DASHBOARD_SUCCESS";
export const EDIT_DASHBOARD_ERROR = "EDIT_DASHBOARD_ERROR";

export const VIEW_DASHBOARD_START = "VIEW_DASHBOARD_START";
export const VIEW_DASHBOARD_SUCCESS = "VIEW_DASHBOARD_SUCCESS";
export const VIEW_DASHBOARD_ERROR = "VIEW_DASHBOARD_ERROR";

export const ACTIVITY_LOG_FETCH_STARTED = "ACTIVITY_LOG_FETCH_STARTED";
export const ACTIVITY_LOG_FETCH_SUCCESS = "ACTIVITY_LOG_FETCH_SUCCESS";
export const ACTIVITY_LOG_FETCH_FAILED = "ACTIVITY_LOG_FETCH_FAILED";

export const CHANGE_DATE_FORMAT_STARTED = "CHANGE_DATE_FORMAT_STARTED";
export const CHANGE_DATE_FORMAT_FAILED = "CHANGE_DATE_FORMAT_FAILED";
export const CHANGE_DATE_FORMAT_SUCCESS = "CHANGE_DATE_FORMAT_SUCCESS";

export const CLEAR_DASHBOARD_DATA = "CLEAR_DASHBOARD_DATA";
export const SET_DASHBOARD_FILTERS = "SET_DASHBOARD_FILTERS";

export const SET_CURRENT_PROFILE_STARTED = "SET_CURRENT_PROFILE_STARTED";
export const SET_CURRENT_PROFILE_SUCCESS = "SET_CURRENT_PROFILE_SUCCESS";
export const SET_CURRENT_PROFILE_FAILED = "SET_CURRENT_PROFILE_FAILED";

export const CHANGE_SORT_ORDER_STARTED = "CHANGE_SORT_ORDER_STARTED";
export const CHANGE_SORT_ORDER_FAILED = "CHANGE_SORT_ORDER_FAILED";
export const CHANGE_SORT_ORDER_SUCCESS = "CHANGE_SORT_ORDER_SUCCESS";

export const ADD_USER_DETAILS_STARTED = "ADD_USER_DETAILS_STARTED";
export const ADD_USER_DETAILS_SUCCESS = "ADD_USER_DETAILS_SUCCESS";
export const ADD_USER_DETAILS_FAILED = "ADD_USER_DETAILS_FAILED";

export const UPDATE_USER_DETAILS_STARTED = "UPDATE_USER_DETAILS_STARTED";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILED = "UPDATE_USER_DETAILS_FAILED";

export const DELETE_USER_DETAILS_STARTED = "DELETE_USER_DETAILS_STARTED";
export const DELETE_USER_DETAILS_SUCCESS = "DELETE_USER_DETAILS_SUCCESS";
export const DELETE_USER_DETAILS_FAILED = "DELETE_USER_DETAILS_FAILES";

export const SET_INSIGHT_DATA = "SET_INSIGHT_DATA";
export const SET_SEARCH_DATA = "SET_SEARCH_DATA";

export const ADD_USER_GROUP_START = "ADD_USER_GROUP_START";
export const ADD_USER_GROUP_SUCCESS = "ADD_USER_GROUP_SUCCESS";
export const ADD_USER_GROUP_FAILED = "ADD_USER_GROUP_FAILED";
export const GET_USER_GROUP_START = "GET_USER_GROUP_START";
export const GET_USER_GROUP_SUCCESS = "GET_USER_GROUP_SUCCESS";
export const GET_USER_GROUP_FAILED = "GET_USER_GROUP_FAILED";
export const UPDATE_USER_GROUP_START = "UPDATE_USER_GROUP_START";
export const UPDATE_USER_GROUP_SUCCESS = "UPDATE_USER_GROUP_SUCCESS";
export const UPDATE_USER_GROUP_FAILED = "UPDATE_USER_GROUP_FAILED";
export const DELETE_USER_GROUP_START = "DELETE_USER_GROUP_START";
export const DELETE_USER_GROUP_SUCCESS = "DELETE_USER_GROUP_SUCCESS";
export const DELETE_USER_GROUP_FAILED = "DELETE_USER_GROUP_FAILED";
//import { getActivityLogPageSize } from "../../../api/settings";
import { handleApiError } from "../../../utils/commonUtils";
import {
  ADD_USER_GROUP_START,
  ADD_USER_GROUP_SUCCESS,
  ADD_USER_GROUP_FAILED,
  GET_USER_GROUP_START,
  GET_USER_GROUP_SUCCESS,
  GET_USER_GROUP_FAILED,
  UPDATE_USER_GROUP_START,
  UPDATE_USER_GROUP_SUCCESS,
  UPDATE_USER_GROUP_FAILED,
  DELETE_USER_GROUP_START,
  DELETE_USER_GROUP_SUCCESS,
  DELETE_USER_GROUP_FAILED,
} from "../../Redux.constants";

export function addUserGroupAction({ name, note }) {
  return async (dispatch, getState) => {
    dispatch({
      type: ADD_USER_GROUP_START,
      payload: {},
    });
    try {
      //todo replace the mock with the actual api call!
      //const { data } = await addUserGroupApi({ name, note });
      const globalState = getState();
      const userGroupStore = globalState.UserGroups.userGroupStore;
      const id = 1 + Math.max(...userGroupStore.map(g => g.id));
      dispatch({
        type: ADD_USER_GROUP_SUCCESS,
        //payload: data.result,
        payload: {
          data: [...userGroupStore, { id, name, note }],
        }
      });
    } catch (error) {
      handleApiError(error, () =>
        dispatch({
          type: ADD_USER_GROUP_FAILED,
          payload: error,
        })
      );
    }
  };
}

export function getUserGroupAction({ pageNumber, pageSize, sortField, sortOrder }) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_USER_GROUP_START,
      payload: {},
    });
    try {
      //todo replace the 'userGroupStore' with the response from the actual api call!
      //const { data } = await getUserGroupApi({ pageNumber, pageSize, sortField, sortOrder});
      const globalState = getState();
      const userGroupStore = globalState.UserGroups.userGroupStore;
      const idx = ((pageNumber < 1 ? 1 : pageNumber) -1) * pageSize;
      dispatch({
        type: GET_USER_GROUP_SUCCESS,
        //payload: data.result,
        payload: {
          data: userGroupStore.slice(idx, idx + pageSize),
          totalCount: userGroupStore.length
        }
      });
    } catch (error) {
      handleApiError(error, () =>
        dispatch({
          type: GET_USER_GROUP_FAILED,
          payload: error,
        })
      );
    }
  };
}

export function updateUserGroupAction(userGroup) {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_USER_GROUP_START,
      payload: {},
    });
    try {
      //const { data } = await updateUserGroupApi(userGroup);
      dispatch({
        type: UPDATE_USER_GROUP_SUCCESS,
        //payload: data.result,
        payload: userGroup,
      });
    } catch (error) {
      handleApiError(error, () =>
        dispatch({
          type: UPDATE_USER_GROUP_FAILED,
          payload: error,
        })
      );
    }
  };
}

export function deleteUserGroupAction(userGroup) {
  return async (dispatch, getState) => {
    dispatch({
      type: DELETE_USER_GROUP_START,
      payload: {},
    });
    try {
      //const { data } = await deleteUserGroupApi(userGroup);
      dispatch({
        type: DELETE_USER_GROUP_SUCCESS,
        //payload: data.result,
        payload: userGroup,
      });
    } catch (error) {
      handleApiError(error, () =>
        dispatch({
          type: DELETE_USER_GROUP_FAILED,
          payload: error,
        })
      );
    }
  };
}
import {
  setAdvanceSearchPayload,
  setAdvancedSearchKeyword,
} from "utils/dataFormatter";
import { errorMEssageHandler } from "utils/ErrorHandler";
import { INITIAL_STATE } from "../initialState";
export function searchStarted(state, payload) {
  return {
    ...state,
    search: {
      ...state.search,
      loading: payload.saved ? false : true,
      error: false,
      searchDone: false,
      savedSearchLoading: payload.saved ? true : false,
      totalResultCount: payload.saved ? state.search.totalResultCount : 0,
    },
  };
}
export function searchSuccess(state, payload) {
  return {
    ...state,
    search: {
      ...state.search,
      loading: false,
      data: payload.data ?? [],
      searchDone: true,
      totalResultCount:
        payload.totalResultCount ?? state.search.totalResultCount,
      error: false,
      loadResultId: payload.loadResultId ? payload.loadResultId : "",
      savedSearchLoading: false,
      insightIds: payload.insightIds ?? [],
    },
  };
}
export function searchFailed(state, payload) {
  return {
    ...state,
    search: {
      ...state.search,
      loading: false,
      error: true,
      data: [],
      savedSearchLoading: false,
      errorMessage: errorMEssageHandler(payload?.response?.status),
    },
  };
}
export function searchSaved(state, payload) {
  return {
    ...state,
    search: {
      ...state.search,
      loading: false,
      saved: payload.fullSavedData ? payload.saved.result : payload.result,
      fullSavedData: payload.fullSavedData
        ? payload.fullSavedData
        : state.search.fullSavedData,
      savedSearchLoading: false,
      savedSearchTotalCount: payload.fullSavedData
        ? payload.saved.resultHits
        : payload.resultHits,
      searchDone: true,
    },
  };
}
export function deleteSaved(state, payload) {
  return {
    ...state,
    search: {
      ...state.search,
      saved: state.search.saved.filter((i) => i.id != payload),
    },
  };
}
export function searchSavedSuccess(state, payload) {
  return {
    ...state,
    search: {
      ...state.search,
      saved: [payload, ...state.search.saved],
      fullSavedData: [payload, ...state.search.fullSavedData],
    },
  };
}
export function setSearchDataState(state, payload) {
  //debugger;
  const { search } = state;
  if (payload.clear) return { ...INITIAL_STATE };
  if (payload.clear) {
    //debugger;
    return { ...INITIAL_STATE };
  }
  return {
    ...state,
    search: {
      ...search,
      error: payload.error ? payload.error : false,
      errorMessage: payload.errorMessage ? payload.errorMessage : "",
      isFromInsights: payload.isFromInsights ? payload.isFromInsights : false,
      pageNumber: payload.pageNumber ? payload.pageNumber : 1,
      value: setAdvancedSearchKeyword({ data: search, payload, key: "value" }),
      advanceSearchPayloadData: setAdvanceSearchPayload({
        data: search,
        payload,
        key: "value",
      }),
      ...payload,
    },
  };
}

